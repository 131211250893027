<template>
  <section>
    <section v-if="isLoading">
      <b-row v-for="index in 3" :key="index">
        <b-skeleton
          class="ml-1 mr-2"
          width="32%"
          height="50%"
          v-for="index in 3"
          :key="index"
        />
      </b-row>
    </section>
    <SummaryContainer
      v-else
      :patientId="patientId"
      :summaryData="summaryData"
      :showTitle="false"
      :cols="cols"
      @refreshSummary="refreshSummary"
    />
  </section>
</template>

<script>
export default {
  name: 'SurgeryInformation',
  props: {
    patientId: {
      type: String,
      required: true
    },
    cols: {
      type: Number,
      default: 12
    }
  },
  components: {
    SummaryContainer: () =>
      import('@/components/Patient/Dashboard/SummaryContainer')
  },
  data: () => ({
    summaryData: [],
    isLoading: false
  }),
  async created() {
    await this.getPatientSummary()
  },
  methods: {
    async getPatientSummary() {
      this.isLoading = true
      try {
        const types = [
          'antecedentes-oftalmologicos',
          'cirurgias-oftalmologicas-anteriores',
          'alergias',
          'antecedentes-pessoais',
          'outros-medicamentos-em-uso',
          'antecedentes-familiares'
        ]
        const { data } = await this.api.getPatientSummary(this.patientId, types)
        this.summaryData = data
      } catch (error) {
        this.$toast.error('Erro ao carregar dados do paciente')
      } finally {
        this.isLoading = false
      }
    },
    refreshSummary(data, id) {
      if (id) {
        const index = this.summaryData.findIndex(item => item.id === id)
        this.summaryData.splice(index, 1)
        return
      }
      this.summaryData.push(data)
      this.summaryData.sort()
    }
  }
}
</script>
